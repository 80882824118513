<template>
	<div class="flex min-h-screen max-w-[1920px] mx-auto">
		<CoolLightBox
			:items="lb_res_items"
			:index="lb_res_index"
			:useZoomBar="true"
			@close="lb_res_index = null"
		>
		</CoolLightBox>
		<CoolLightBox
			:items="lb_acc_items"
			:index="lb_acc_index"
			:useZoomBar="true"
			@close="lb_acc_index = null"
		>
		</CoolLightBox>
		<CoolLightBox
			:items="vc_map_items"
			:index="vc_map_index"
			:useZoomBar="true"
			@close="vc_map_index = null"
		>
		</CoolLightBox>

		<div class="flex-none w-0 lg:w-[95px] relative z-10">
			<div class="fixed top-0 bottom-0 lg:left-0 bg-siteBlue-900 w-[48px] lg:w-[95px] flex flex-col justify-between items-stretch divide-y divide-[#0004] text-siteGreen text-3xl duration-200" :class="isOpen ? 'left-0 ' : 'left-[-48px]'">
				<!-- <div class="absolute top-0 bottom-0 left-full w-3 bg-gradient-to-r from-[#0003] pointer-events-none"></div> -->
				<div class="relative flex-none overflow-hidden duration-500" :class="isTop ? 'max-h-0' : 'max-h-full'">
					<a href="#mainpage" v-smooth-scroll class="relative block px-2 py-6 lg:p-4 X__lg:-mr-16 X__lg:rounded-br-lg bg-siteBlue Xshadow-lg">
						<img src="@/assets/img/logo-white-128.png" alt="" width="128" height="60">
					</a>
				</div>
				<a href="#units" v-smooth-scroll class="relative flex items-center justify-center h-full p-2 duration-200 group">
					<div class="hidden lg:block absolute p-4 font-light text-white duration-200 opacity-0 pointer-events-none bg-siteBlue -left-full group-hover:duration-700 group-hover:left-full group-hover:opacity-100">
						Units
					</div>
					<div class="absolute inset-0 duration-200 group-hover:bg-siteBlue" :class="{'bg-siteBlue': isActive.units}"></div>
					<i class="relative duration-200 group-hover:text-white siteicon-units" :class="{'text-white': isActive.units}" />
				</a>
				<a href="#amenities" v-smooth-scroll class="relative flex items-center justify-center h-full p-2 duration-200 group">
					<div class="hidden lg:block absolute p-4 font-light text-white duration-200 opacity-0 pointer-events-none bg-siteBlue -left-full group-hover:duration-700 group-hover:left-full group-hover:opacity-100">
						Amenities
					</div>
					<div class="absolute inset-0 duration-200 group-hover:bg-siteBlue" :class="{'bg-siteBlue': isActive.amenities}"></div>
					<i class="relative duration-200 group-hover:text-white siteicon-amenities" :class="{'text-white': isActive.amenities}" />
				</a>
				<a href="#location" v-smooth-scroll class="relative flex items-center justify-center h-full p-2 duration-200 group">
					<div class="hidden lg:block absolute p-4 font-light text-white duration-200 opacity-0 pointer-events-none bg-siteBlue -left-full group-hover:duration-700 group-hover:left-full group-hover:opacity-100">
						Location
					</div>
					<div class="absolute inset-0 duration-200 group-hover:bg-siteBlue" :class="{'bg-siteBlue': isActive.location}"></div>
					<i class="relative duration-200 group-hover:text-white siteicon-location" :class="{'text-white': isActive.location}" />
				</a>
				<!-- <a href="#contact" v-smooth-scroll class="relative flex items-center justify-center h-full p-2 duration-200 group hover:bg-siteBlue" :class="{'bg-siteBlue': isActive.contact}">
					<i class="duration-200 group-hover:text-white siteicon-contact" :class="{'text-white': isActive.contact}" />
				</a> -->
				<button @click="toggleMenu" class="!border-t-0 text-white absolute lg:hidden bg-siteBlue-300 top-1/2 left-full h-12 w-4 -translate-y-1/2">
					<svg class="duration-200 -rotate-90" :class="isOpen ? 'scale-y-[-1]' : ''" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
						<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
					</svg>
				</button>
			</div>
			<!-- <a href="tel:+639178494600" class="fixed top-0 right-0 flex items-center gap-4 p-4 text-xl text-white duration-200 bg-siteGreen hover:bg-siteGreen-400 lg:text-2xl rounded-bl-md">
				<img class="w-6 h-6" src="@/assets/img/icon-phone.png" alt="" width="50" height="50">
				<div class="-mt-2">0917 849 4600</div>
			</a> -->
		</div>

		<div id="mainpage" class="flex flex-col w-full min-h-screen lg:min-h-[calc(100vh+64px)] home">

			<!-- <SiteHeader /> -->

			<div class="min-h-screen lg:min-h-[calc(100vh+64px)] section bg-tile">
				<div class="relative flex h-full xl:static lg:overflow-hidden">
					<img class="opacity-20 lg:opacity-100 absolute lg:static top-0 left-0 flex-none w-full lg:w-auto h-full object-cover lg:max-w-[calc(100%/3)] X__xl:max-w-none X__xl:max-h-[calc(100vh+64px)] img-faderight" src="@/assets/img/facade-2.jpg" alt="" width="565" height="1080">
					<div class="relative flex flex-col justify-center w-full h-full px-4 py-8 text-center lg:pt-16 xl:pt-8 lg:pl-0 lg:pr-16 xl:pr-24">
						<img class="mx-auto h-auto lg:h-1/3 max-h-[200px] w-auto" src="@/assets/img/logo-big.png" alt="" width="960" height="451">
						<div class="mt-[4vh] text-3xl font-bold lg:text-4xl xl:text-5xl text-siteBlue font-salvalyn">
							Singapore-Inspired Living in <span class="sm:inline-block">Mandaue City</span>
						</div>
						<!-- <div class="text-siteBlue uppercase tracking-[.2em] text-2xl lg:text-4xl mt-[4vh]">
							Passion For Life
						</div> -->
						<div class="text-siteBlue font-light text-2xl lg:text-4xl mt-[4vh]">
							Starts at Php 14,340 per month
						</div>
						<!-- <div class="mt-[6vh] font-light lg:text-2xl">
							Worldwide Central Properties, Inc. partners with the world’s largest serviced residence owner-operator, The Ascott Limited for another hotel and residential offering in the industrial center of Southern Philippines, Mandaue City.
						</div> -->
						<!-- <div class="mt-[6vh] font-light lg:text-2xl">
							<p class="font-medium">SUITES@SIX EARLY MOVE IN PROMO</p>
							<p class="mt-4">Reserve a residential unit at Vitale Suites to experience a buyer perk of your choice: up to 105K cash discount, trips, tech, shopping vouchers and lifestyle products.</p>
						</div> -->
						<div class="mt-[6vh] font-light lg:text-2xl">
							<p class="font-medium">Big CASH DISCOUNTS await <span class="block lg:inline-block">Buyers from 3% to 21%!</span></p>
						</div>
						<!-- <div class="mt-[4vh] lg:mt-[6vh] font-light grid grid-cols-3 lg:pb-16 items-center lg:items-start gap-4 lg:gap-0">
							<div class="space-y-4 text-base uppercase">
								<div class="hidden lg:block">Developed By:</div>
								<img class="mx-auto max-h-[100px] w-auto" src="@/assets/img/wcpi-logo.png" alt="" width="419" height="200">
							</div>
							<div class="space-y-4 text-base uppercase">
								<div class="hidden lg:block">Property Management:</div>
								<img class="mx-auto max-h-[100px] w-auto" src="@/assets/img/ascott-logo.png" alt="" width="182" height="200">
							</div>
							<div class="space-y-4 text-base uppercase">
								<div class="hidden lg:block">Serviced Residence Operator:</div>
								<img class="mx-auto max-h-[100px] w-auto" src="@/assets/img/citadines-logo.png" alt="" width="410" height="200">
							</div>
						</div> -->
						<ContactForm id="inquiry" class="mt-[4vh] lg:mt-[6vh] mb-6 lg:mb-0" />
					</div>

					<div class="absolute top-[calc(100vh-64px)] justify-center hidden left-0 right-0 duration-200 pointer-events-none lg:flex" :class="isTop ? '':'opacity-0'">
						<a href="#units" v-smooth-scroll class="flex flex-col items-center justify-center px-4 py-2 text-black duration-1000 bg-[#FFFB] hover:bg-siteGreen-200" :class="isTop ? 'pointer-events-auto':''">
							<div class="lg:hidden">&#9650;</div>
							<div class="text-xs italic opacity-50 lg:hidden">swipe</div>
							<svg class="hidden opacity-50 lg:block" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"></path>
							</svg>
							<div class="hidden text-xs italic opacity-50 lg:block">scroll</div>
						</a>
					</div>
				</div>
			</div>


			<div id="units" class="relative min-h-screen -mt-8 lg:-mt-16 section">
				<div class="flex flex-col h-full">

					<div class="flex-none pt-8 lg:pt-16">
						<div class="py-[5px] bg-white border-t-4 border-b-4 border-siteGreen">
							<div class="text-white bg-greens">
								<div class="container flex flex-col-reverse pt-12 lg:flex-row lg:pt-0 gap-y-8">
									<img class="flex-none w-auto h-auto max-h-[300px] object-contain lg:-mt-10" src="@/assets/img/vitale-lady.png" alt="" width="350" height="320">
									<div class="flex flex-col justify-center w-full lg:px-8 text-center gap-y-[2vh]">
										<div class="text-4xl font-bold uppercase font-salvalyn">Passion For Life</div>
										<p class="font-light lg:text-xl">Vitale is a Latin term which means life giving, an inspiration that the mixed-use development reflects in various aspects of its “live, work and play well” concept.</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="h-full subsection bg-vitale-2">
						<div class="container relative flex flex-col justify-between h-full gap-12 pt-12 pb-12 text-center lg:pt-16 lg:pb-24">
							<div class="space-y-8">
								<div class="text-4xl font-bold uppercase font-salvalyn text-siteBlue">Thoughtfully-Planned Living Spaces</div>
								<p class="font-light lg:text-xl">Vitale Suites offers a versatile set of residential units that fit various lifestyles and homemakers, <span class="lg:inline-block">from young self starters, health buffs, active seniors and mobility challenged individuals.</span></p>
							</div>

							<div class="grid items-center gap-8 lg:grid-cols-3">
								<div>
									<a href="#mainpage" title="Inquire Now" v-smooth-scroll onclick="setTimeout( ()=> document.getElementById('name').focus(), 1000 );" class="inline-block p-4 space-y-4 duration-200 rounded group hover:bg-siteGreen-100 hover:shadow-md">
										<div class="text-xl text-siteBlue">
											Residential Suites:
										</div>
										<div class="font-light">
											<div class="">Studio Suite</div>
											<div class="text-sm">(25 square meters)</div>
										</div>
										<div class="font-light">
											<div class="">1-Bedroom Suite with Balcony</div>
											<div class="text-sm">(48 square meters)</div>
										</div>
									</a>
								</div>
								<owl-carousel
									class="overflow-hidden lg:col-span-2"
									:dots="true"
									:dotsEach="1"
									:nav="false"
									:loop="false"
									:rewind="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="16"
									:responsive="{
										0: {items: 1},
										768: {items: 2},
									}"
								>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_res_index = 0" src="@/assets/img/residential01.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_res_index = 1" src="@/assets/img/residential02.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_res_index = 2" src="@/assets/img/residential03.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_res_index = 3" src="@/assets/img/residential04.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_res_index = 4" src="@/assets/img/residential05.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_res_index = 5" src="@/assets/img/residential06.jpg" alt="" width="403" height="268">
									</div>

								</owl-carousel>
								<!-- <div class="space-y-3 lg:col-span-2">
									<div class="grid gap-4 lg:grid-cols-2">
										<img src="@/assets/img/residential01.jpg" alt="" width="403" height="268">
										<img class="hidden lg:block" src="@/assets/img/residential02.jpg" alt="" width="403" height="268">
									</div>
									<div class="flex justify-center gap-3">
										<div class="w-2 h-2 bg-gray-500 rounded-full"></div>
										<div class="w-2 h-2 bg-gray-300 rounded-full"></div>
										<div class="w-2 h-2 bg-gray-300 rounded-full"></div>
										<div class="w-2 h-2 bg-gray-300 rounded-full"></div>
									</div>
								</div> -->
							</div>

							<div class="grid items-center gap-8 lg:grid-cols-3">
								<div class="lg:order-2">
									<a href="#mainpage" title="Inquire Now" v-smooth-scroll onclick="setTimeout( ()=> document.getElementById('name').focus(), 1000 );" class="inline-block p-4 space-y-4 duration-200 rounded group hover:bg-siteGreen-100 hover:shadow-md">
										<div class="text-xl text-siteBlue">
											Accessible Suites:
										</div>
										<div class="font-light">
											<div class="">Studio Suite</div>
											<div class="text-sm">(33 square meters)</div>
										</div>
										<div class="font-light">
											<div class="">1-Bedroom Suite with Balcony</div>
											<div class="text-sm">(48 square meters)</div>
										</div>
									</a>
								</div>
								<owl-carousel
									class="overflow-hidden lg:col-span-2 lg:order-1"
									:dots="true"
									:dotsEach="1"
									:nav="false"
									:loop="false"
									:rewind="true"
									:autoplay="true"
									:autoHeight="false"
									:autoplayTimeout="5000"
									:touchDrag="true"
									:mouseDrag="true"
									:margin="16"
									:responsive="{
										0: {items: 1},
										768: {items: 2},
									}"
								>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 0" src="@/assets/img/accessible01.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 1" src="@/assets/img/accessible02.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 2" src="@/assets/img/accessible03.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 3" src="@/assets/img/accessible04.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 4" src="@/assets/img/accessible05.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 5" src="@/assets/img/accessible06.jpg" alt="" width="403" height="268">
									</div>
									<div class="bg-white">
										<img class="duration-200 cursor-pointer hover:opacity-80" @click="lb_acc_index = 6" src="@/assets/img/accessible07.jpg" alt="" width="403" height="268">
									</div>

								</owl-carousel>
								<!-- <div class="space-y-3 lg:col-span-2 lg:order-1">
									<div class="grid gap-4 lg:grid-cols-2">
										<img src="@/assets/img/accessible01.jpg" alt="" width="403" height="268">
										<img class="hidden lg:block" src="@/assets/img/accessible02.jpg" alt="" width="403" height="268">
									</div>
									<div class="flex justify-center gap-3">
										<div class="w-2 h-2 bg-gray-500 rounded-full"></div>
										<div class="w-2 h-2 bg-gray-300 rounded-full"></div>
										<div class="w-2 h-2 bg-gray-300 rounded-full"></div>
										<div class="w-2 h-2 bg-gray-300 rounded-full"></div>
									</div>
								</div> -->
							</div>

							<a href="#mainpage" v-smooth-scroll onclick="setTimeout( ()=> document.getElementById('name').focus(), 1000 );" class="rounded-sm bg-siteGreen self-center text-white text-3xl p-1 !mt-16 hover:bg-siteGreen-400 duration-200">
								<div class="px-12 pt-3 pb-4 leading-none border-2 border-white rounded-sm">
									Inquire Now!
								</div>
							</a>

							<div class="text-xs italic text-gray-400">
								Lifestyle Images used in this material are representations only. The developer reserves the right to change and/ or improve the details of the project without prior notice. <span class="lg:inline-block">All information found herein do not form part of a contract or sale.</span>
							</div>
						</div>
					</div>

				</div>
			</div>


			<div id="amenities" class="relative min-h-screen pt-8 -mt-8 lg:pt-16 lg:-mt-16 section">
				<div class="flex flex-col h-full">

					<div class="flex-none">
						<div class="py-[5px] bg-white border-t-4 border-b-4 border-siteGreen">
							<div class="text-white bg-greens">
								<div class="container flex flex-col pt-12 lg:flex-row lg:pt-0 gap-y-8">
									<div class="flex flex-col justify-center w-full lg:px-8 text-center gap-y-[2vh]">
										<div class="text-4xl font-bold uppercase font-salvalyn">Wellness-Centered living at <span class="inline-block">its peak</span></div>
										<p class="font-light lg:text-xl">Vitale Suites features a residential, recreational and retail mix that puts well being at the center of its dynamic, stylish and wellness-driven design.</p>
									</div>
									<img class="flex-none w-auto h-auto max-h-[300px] object-contain lg:-mt-10" src="../assets/img/working-out.png" alt="" width="303" height="300">
								</div>
							</div>
						</div>
					</div>

					<div class="h-full subsection bg-vitale-2 flipped">
						<div class="container relative flex flex-col justify-between h-full gap-12 py-12 text-center lg:py-16">
							<div class="space-y-8">
								<div class="text-4xl font-bold uppercase font-salvalyn text-siteBlue">Hotel Living At Home</div>
								<p class="font-light lg:text-xl">As the home of Citadines Mandaue Cebu, Vitale Suites residents get to enjoy hotel living at home <span class="lg:inline-block">with access to the hotel’s various services for a fee.</span></p>
							</div>

							<ul class="self-center pl-6 font-light text-left list-disc col-count-2 gap-x-16">
								<li>Housekeeping Service</li>
								<li>Laundry and Dry Cleaning Services</li>
								<li>Babysitting Service</li>
								<li>Limousine Service</li>
								<li>Business Centre Access</li>
								<li>Conference Room Rental</li>
								<li>Airport Transfer</li>
							</ul>

							<div class="grid self-center lg:grid-cols-3 gap-x-32 gap-y-8">
								<div class="space-y-4 lg:col-span-2">
									<div class="text-siteBlue">
										<div class="text-2xl font-bold uppercase font-salvalyn">Residential Recreational Amenities</div>
										<div class="">(2nd Floor/3rd Floor/6th Floor)</div>
									</div>
									<div class="flex flex-col items-center gap-4">
										<ul class="pl-6 font-light text-left list-disc col-count-2 gap-x-16">
											<li>Swimming Pool**</li>
											<li>Kiddie Play Area</li>
											<li>Residence Lounge</li>
											<li>Rock Climbing Wall</li>
											<li>Jogging Path</li>
											<li>Viewing Deck</li>
											<li>Organic Herb Garden</li>
											<li>Outdoor Fitness Area</li>
											<li>Golf Bag Lockers</li>
											<li>Indoor Bicycle Parking</li>
										</ul>
										<div class="text-sm font-light">
											(**Shared Amenity)
										</div>
									</div>
								</div>
								<div class="space-y-4">
									<div class="text-siteBlue">
										<div class="text-2xl font-bold uppercase font-salvalyn">Retail Shops</div>
										<div class="">(Ground Floor)</div>
									</div>
									<div class="flex flex-col items-center gap-4">
										<ul class="pl-6 font-light text-left list-disc">
											<li>Restaurant</li>
											<li>Eye Center</li>
											<li>Health Food Store</li>
											<li>Sports Rehab Center</li>
										</ul>
									</div>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>


			<div id="location" class="relative text-white section bg-siteBlue-400">
				<div class="container relative flex flex-col justify-between h-full gap-12 py-12 text-center lg:py-16">
					<div class="space-y-8">
						<div class="text-4xl font-bold uppercase font-salvalyn">Live Next Door To The Good Life!</div>
						<ul class="font-light list-disc list-inside lg:text-xl">
							<li>At the center of Cebu Business Park and Gatewalk Central</li>
							<li>Across Beep Route 2, F. Cabahug Stop</li>
						</ul>
					</div>
					<img @click="vc_map_index = 0" class="w-full h-auto cursor-pointer" src="@/assets/img/vicinity-map.jpg" alt="" width="1248" height="416">
					<div class="space-y-4">
						<div>
							<i class="siteicon-location" /> L.O. Building, Gorordo Avenue, Camputhaw, Cebu City
						</div>
						<!-- <div class="text-xl">
							<img class="inline-block w-5 h-5 mr-1" src="@/assets/img/icon-phone.png" alt="" width="50" height="50"> <a class="hover:underline" href="tel:+639178494600">0917 849 4600</a>
						</div> -->
					</div>
				</div>
			</div>


			<!-- <div id="contact" class="relative text-white section bg-siteBlue bg-facade">
				<div class="container relative flex flex-col justify-between h-full gap-12 py-12 text-center lg:py-16">
					<div class="text-4xl font-bold uppercase font-salvalyn">Get In Touch With Us</div>
					<ContactForm />
				</div>
			</div> -->


			<SiteFooter />

			<!-- <div class="fixed bottom-[30px] right-[40px]" id="waiting-chat-widget">
				<div class="relative overflow-visible">
					<span class="absolute animate-pingu z-[1] inline-flex h-full w-full rounded-xl bg-[#2a3a11] opacity-75"></span>
					<div class="flex items-center relative z-[2] bg-[#6d9138] px-[14px] py-[8px] rounded-xl text-[16px] text-white">
						<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
							<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
							<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
						</svg>
						<div>Loading chat widget...</div>
					</div>
				</div>
			</div> -->

		</div>

		<!-- <img class="fixed rounded-full bottom-6 right-6 h-15 w-15" src="@/assets/img/chat-temp.png" alt="" width="60" height="60" style="box-shadow: #00000026 0px 4px 12px 0px;"> -->

	</div>
</template>

<script>
// @ is an alias to /src
// import SiteHeader from '@/components/SiteHeader.vue'
// import MenuItem from '@/components/MenuItem.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'index-page',
	components: {
		// SiteHeader,
		// MenuItem,
		SiteFooter,
		ContactForm,
	},
	data() {
		return {
			isOpen: false,
			isTop: true,
			isActive: {
				units: false,
				amenities: false,
				location: false,
				contact: false,
			},
			lb_res_items: [
				require('@/assets/img/residential01-full.jpg'),
				require('@/assets/img/residential02-full.jpg'),
				require('@/assets/img/residential03-full.jpg'),
				require('@/assets/img/residential04-full.jpg'),
				require('@/assets/img/residential05-full.jpg'),
				require('@/assets/img/residential06-full.jpg'),
			],
			lb_res_index: null,
			lb_acc_items: [
				require('@/assets/img/accessible01-full.jpg'),
				require('@/assets/img/accessible02-full.jpg'),
				require('@/assets/img/accessible03-full.jpg'),
				require('@/assets/img/accessible04-full.jpg'),
				require('@/assets/img/accessible05-full.jpg'),
				require('@/assets/img/accessible06-full.jpg'),
				require('@/assets/img/accessible07-full.jpg'),
			],
			lb_acc_index: null,
			vc_map_items: [
				require('@/assets/img/vicinity-map-full.jpg'),
			],
			vc_map_index: null,
		}
	},
	// mounted () {
	// 	// window.addEventListener('scroll', this.handleScroll, {passive: true});
	// 	window.addEventListener('scroll', this.handleScroll, {passive: true});
	// 	this.handleScroll();

	// 	// Create a MutationObserver instance
	// 	const observer = new MutationObserver((mutationsList, observer) => {
	// 		// Loop through the mutations
	// 		mutationsList.forEach((mutation) => {
	// 			// Check if child elements were added to the body
	// 			if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
	// 				// Check if any added node has the desired class name
	// 				const addedNodes = Array.from(mutation.addedNodes);
	// 				const divsWithClassName = addedNodes.find(node => {
	// 					return node instanceof HTMLElement && node.classList.contains('fb_dialog');
	// 				});

	// 				if (divsWithClassName) {
	// 					// Process the newly added elements here
	// 					const waitingChatWidget = document.getElementById('waiting-chat-widget');
	// 					waitingChatWidget.classList.add('hidden');
	// 				}
	// 			}
	// 		});
	// 	});

	// 	// Define the type of mutations to observe on the body
	// 	const config = {
	// 		childList: true, // Observe child elements being added or removed
	// 		subtree: true,  // Observe all descendants of the body
	// 	};

	// 	// Start observing the body with the specified configuration
	// 	observer.observe(document.body, config);

	// 	// To disconnect the observer when you're done:
	// 	// observer.disconnect();
	// },
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		toggleMenu() {
			this.isOpen = !this.isOpen;
		},
		handleScroll() {
			this.isTop = window.scrollY < 200;

			let units = document.getElementById("units");
			if (window.scrollY >= units.offsetTop - window.innerHeight/2 && window.scrollY < units.offsetTop + units.scrollHeight - window.innerHeight/2) {
				this.isActive.units = true;
			} else this.isActive.units = false;

			let amenities = document.getElementById("amenities");
			if (window.scrollY >= amenities.offsetTop - window.innerHeight/2 && window.scrollY < amenities.offsetTop + amenities.scrollHeight - window.innerHeight/2) {
				this.isActive.amenities = true;
			} else this.isActive.amenities = false;

			let location = document.getElementById("location");
			if (window.scrollY >= location.offsetTop - window.innerHeight/2 && window.scrollY < location.offsetTop + location.scrollHeight - window.innerHeight/2) {
				this.isActive.location = true;
			} else this.isActive.location = false;

			// let contact = document.getElementById("contact");
			// if (window.scrollY >= contact.offsetTop - window.innerHeight/2 && window.scrollY < contact.offsetTop + contact.scrollHeight - window.innerHeight/2) {
			// 	this.isActive.contact = true;
			// } else this.isActive.contact = false;

			// console.log(window.innerHeight);
			// this.winScrollY.global = window.scrollY / 1.5;
			// this.winScrollY.banner = 128 + (window.scrollY / 1.5);
			// this.winScrollY.woods = window.scrollY / 2;
			// this.winScrollY.about = document.getElementById("myElement").offsetTop + (window.scrollY / 1.125);
		},
	},
}
</script>

<style>
/* #mainpage {
	background-image: url('~@/assets/img/bg01.jpg');
	@apply bg-cover bg-center bg-no-repeat bg-fixed;
} */
.animate-pingu {
	animation: pingu 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes pingu {
	75%, 100% {
		/* @apply scale-y-150 space-x-100; */
		transform: scaleX(1.1) scaleY(1.5);
		opacity: 0;
	}
}

.bg-tile {
	background-image: url('~@/assets/img/bg-tile.jpg');
	@apply bg-center bg-contain;
}
@screen lg {
	.img-faderight {
		-webkit-mask-image:-webkit-linear-gradient(left, rgba(0,0,0,1) 75%, rgba(0,0,0,0));
		mask-image: linear-gradient(to right, rgba(0,0,0,1) 75%, rgba(0,0,0,0));
	}
}
.bg-greens {
	background-image: url('~@/assets/img/greens.jpg');
	@apply bg-cover bg-center bg-no-repeat;
}
.bg-vitale-2 {
	@apply relative;
}
.bg-vitale-2::before {
	content: '';
	background-image: url('~@/assets/img/vitale-bg-2.png');
	@apply absolute inset-0 bg-cover lg:bg-contain bg-left bg-no-repeat pointer-events-none;
}
.bg-vitale-2.flipped::before {
	@apply scale-x-[-1];
}
.bg-facade {
	background-image: url('~@/assets/img/bg-facade.jpg');
	@apply bg-cover bg-bottom bg-no-repeat;
}

.woods-slider>[id^="carousel_prev"] {
	display: block !important;
}
.parallax {
	-webkit-perspective: 1px;
	perspective: 1px;
}
.parallax-bg {
	/* @apply inset-0; */
	/* transform: translate(-50%, -50%); */
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translateZ(-1px) scale(2);
	transform: translateZ(-1px) scale(2);
	/* bottom: -200%; */
	/* background-image: url('@/assets/img/wood-bg.jpg'); */
}
.banner-section {
	/* background-image: url('~@/assets/img/buybanner.jpg');
	@apply bg-cover bg-top bg-no-repeat; */
}
@screen lg {
	.banner-section {
		/* aspect-ratio: 5/2; */
	}
}
</style>
