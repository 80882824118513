<template>
	<form @submit.prevent="submit">
		<div class="space-y-4 text-left text-black font-light">

			<div class="grid lg:grid-cols-2 xl:grid-cols-6 gap-4">
				<div class="relative xl:col-span-3">
					<input id="name" type="text" :class="errors && errors.name ? 'border-red-500' : 'border-gray-400'" placeholder="Name"
						v-model="fields.name" required
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
					>
					<label for="name"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.name ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-400 peer-focus:text-black'"
					>Name</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.name ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<InputError :field="errors.name" class="mt-1 Xmb-[10px] text-xs text-red-400"/>
				</div>

				<div class="relative xl:col-span-3">
					<input id="mobile" type="tel" :class="errors && errors.mobile ? 'border-red-500' : 'border-gray-400'" placeholder="Mobile Number"
						minlength="11"
						v-model="fields.mobile" required
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
					>
					<label for="mobile"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.mobile ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-400 peer-focus:text-black'"
					>Mobile Number</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.mobile ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<InputError :field="errors.mobile" class="mt-1 Xmb-[10px] text-xs text-red-400"/>
				</div>

				<div class="relative xl:col-span-3">
					<input id="email" type="email" :class="errors && errors.email ? 'border-red-500' : 'border-gray-400'" placeholder="Email"
						v-model="fields.email" required
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
					>
					<label for="email"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.email ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-400 peer-focus:text-black'"
					>Email</label>
					<span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.email ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span>
					<InputError :field="errors.email" class="mt-1 Xmb-[10px] text-xs text-red-400"/>
				</div>

				<div class="relative flex items-stretch xl:col-span-3">
					<div class="relative w-full">
						<input id="city_state" type="text" :class="errors && errors.city_state ? 'border-red-500' : 'border-gray-400'" placeholder="City / State"
							v-model="fields.city_state" required
							class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
						>
						<label for="city_state"
							class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
							:class="errors && errors.city_state ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-400 peer-focus:text-black'"
						>City / State</label>
						<span
							class="absolute text-xl pointer-events-none right-3 top-3"
							:class="errors && errors.city_state ? 'text-red-500' : 'text-[#9298a5]'"
						>*</span>
						<InputError :field="errors.city_state" class="mt-1 Xmb-[10px] text-xs text-red-400"/>
					</div>
				</div>

				<div class="relative flex flex-col gap-y-2 justify-between xl:col-span-3">
					<!-- <div class="text-sm text-gray-500">What type of unit are you interested in:</div> -->
					<div class="relative">
						<select :class="errors && errors.unit_type ? 'border-red-500' : 'border-gray-400'" placeholder="Unit Type"
							v-model="fields.unit_type" required
							class="w-full invalid:text-gray-400 px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border shadow-inner focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
						>
							<option value="" disabled selected>What type of unit are you interested in?</option>
							<option value="Studio">Studio</option>
							<option value="1BR">1BR</option>
							<option value="Executive">Executive</option>
						</select>
						<span
							class="absolute text-xl pointer-events-none right-5 top-3"
							:class="errors && errors.unit_type ? 'text-red-500' : 'text-[#9298a5]'"
						>*</span>
						<InputError :field="errors.unit_type" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
					</div>
				</div>

				<!-- <div class="relative flex flex-col gap-y-2 justify-between xl:col-span-3"> -->
					<!-- <div class="text-sm text-gray-500">What type of unit are you interested in:</div> -->
					<!-- <div class="relative">
						<select :class="errors && errors.perks_promo ? 'border-red-500' : 'border-gray-400'"
							v-model="fields.perks_promo" required
							class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border shadow-inner focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
						>
							<option v-for="(promo, i) in promos[fields.unit_type]" :key="i" :value="promo" :disabled="!fields.unit_type">{{ promo }}</option>
						</select>
						<span
							class="absolute text-xl pointer-events-none right-5 top-3"
							:class="errors && errors.perks_promo ? 'text-red-500' : 'text-[#9298a5]'"
						>*</span>
						<InputError :field="errors.perks_promo" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
					</div>
				</div> -->

				<div class="relative flex flex-col gap-y-2 justify-between xl:col-span-3">
					<!-- <div class="text-sm text-gray-500">How soon are you planning to buy your own condo:</div> -->
					<div class="relative">
						<select :class="errors && errors.plan ? 'border-red-500' : 'border-gray-400'" placeholder="Plan To Buy"
							v-model="fields.plan" required
							class="w-full invalid:text-gray-400 px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border shadow-inner focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
						>
							<option value="" disabled selected>How soon are you planning to buy your own condo?</option>
							<option value="As soon as possible">As soon as possible</option>
							<option value="Within 30 days">Within 30 days</option>
							<option value="Within 60 days">Within 60 days</option>
							<option value="Within 90 days">Within 90 days</option>
							<option value="Within 3 to 6 months">Within 3 to 6 months</option>
							<option value="Not anytime soon">Not anytime soon</option>
						</select>
						<span
							class="absolute text-xl pointer-events-none right-5 top-3"
							:class="errors && errors.plan ? 'text-red-500' : 'text-[#9298a5]'"
						>*</span>
						<InputError :field="errors.plan" class="mt-1 Xmb-[10px] text-xs text-red-500"/>
					</div>
				</div>

				<!-- <div class="relative">
					<textarea rows="6" id="message" type="text" :class="errors && errors.message ? 'border-red-500' : 'border-gray-400'" placeholder="Message"
						v-model="fields.message" required
						class="w-full px-3 py-3 text-sm leading-tight placeholder-transparent bg-white border X__rounded shadow-inner appearance-none focus:shadow-outline focus:outline-none peer focus:ring-0 focus:border-siteGreen focus-visible:border-siteGreen"
					/>
					<label for="message"
						class="absolute px-1 text-xs duration-200 transition-all bg-white cursor-text -top-2 left-1 peer-focus:bg-white rounded peer-focus:-top-2 peer-focus:left-1 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[12px] peer-placeholder-shown:left-[9px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent text-black placeholder-shown:p-0 pointer-events-none"
						:class="errors && errors.message ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-400 peer-focus:text-black'"
					>Message</label> -->
					<!-- <span
						class="absolute text-xl pointer-events-none right-3 top-3"
						:class="errors && errors.message ? 'text-red-500' : 'text-[#9298a5]'"
					>*</span> -->
					<!-- <InputError :field="errors.message" class="mt-1 Xmb-[10px] text-xs text-red-400"/>
				</div> -->

			</div>

			<div class="text-center">
				<button type="submit" v-bind:disabled="busy"
					class="flex font-normal justify-center px-4 lg:px-16 py-4 bg-siteGreen text-xl duration-200 hover:bg-siteGreen-400 text-white focus:outline-none mx-auto">
					<span class="px-2">
						Click here to learn more.
						<svg class="inline-block w-6 h-6" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" v-if="busy">
							<defs>
								<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
									<stop stop-color="#fff" stop-opacity="0" offset="0%"/>
									<stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
									<stop stop-color="#fff" offset="100%"/>
								</linearGradient>
							</defs>
							<g fill="none" fill-rule="evenodd">
								<g transform="translate(1 1)">
									<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</path>
									<circle fill="#fff" cx="36" cy="18" r="1">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</circle>
								</g>
							</g>
						</svg>
					</span>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
	mixins: [hapiMixins],
	components: { InputError },
	data() {
		return {
			endpoint: "https://hapiform.sg/api/845ccf17-bd97-4dae-a577-4693ea26f98a",
			redirectTo: "/thank-you/",
			fields: {
				unit_type: '',
				industry: '',
				plan: '',
			},
			// promos: {
			// 	'': [
			// 		'Please select a unit first.'
			// 	],
			// 	'Studio': [
			// 		'Thailand Trip for 2',
			// 		'Php 50,000 Ikea GC',
			// 		'Php 50,000 Sodexo HC',
			// 		'iPhone15 128GB + Apple Series Watch',
			// 		'Php 50,000 Outright Cash Discount',
			// 	],
			// 	'1BR': [
			// 		'Thailand Trip for 2',
			// 		'Php 50,000 Ikea GC',
			// 		'Php 50,000 Sodexo HC',
			// 		'iPhone15 128GB + Apple Series Watch',
			// 		'Php 50,000 Outright Cash Discount',
			// 	],
			// 	'Executive': [
			// 		'Korea Trip for 2',
			// 		'Singapore Trip for 2',
			// 		'Php 105,000 Sodexo HC',
			// 		'iPhone 15 128GB + Apple Series Watch',
			// 		'Php 105,000 Outright Cash Discount',
			// 	],
			// },
		}
	}
}
</script>

<style>
select {
	appearance: menulist-button;
}
select:invalid option:not(:disabled) {
	color: black;
}
</style>